import React from 'react'

import * as styles from './../styles/TemplateArticle.module.scss'

import Breadcrumbs from '../components/Breadcrumbs'
import LayoutRoot from '../components/LayoutRoot'
import AuthMarkdown from '../components/AuthMarkdown'
import RelatedArticles from '../components/RelatedArticles'

interface ArticleProps {
  pageContext: {
    article: Article
    relatedArticles: Article[]
  }
}

const Article: React.FC<ArticleProps> = ({ pageContext }) => {
  const article = pageContext.article
  const relatedArticles = pageContext.relatedArticles
  const breadcrumbs = [
    {
      name: '首页',
      path: '/news'
    },
    {
      name: '文章详情'
    }
  ]
  return (
    <LayoutRoot noNavigator>
      <div className={styles.articleWrapper}>
        <div className={styles.breadWrapper}>
          <Breadcrumbs data={breadcrumbs} />
        </div>
        <div className={styles.articleTitle}>{article?.title}</div>
        <div className={styles.divider} />
        <div className={styles.contentWrapper}>
          <AuthMarkdown content={article?.content.data?.content} />
        </div>
        <RelatedArticles relatedArticles={relatedArticles} />
      </div>
    </LayoutRoot>
  )
}

export default Article
