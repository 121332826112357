import * as React from 'react'
import * as globalStyles from './../styles/Global.module.scss'
import * as ArticleTempleteStyles from './../styles/TemplateArticle.module.scss'
import * as LayoutRootStyles from './../styles/LayoutRoot.module.scss'
import { Link } from 'gatsby'

interface RelatedArticlesProps {
  relatedArticles?: Article[]
}
const RelatedArticles: React.FC<RelatedArticlesProps> = ({ relatedArticles = [] }) => {
  const relatedArticleList = (
    <ul>
      {relatedArticles.map(({ id, title }) => {
        return (
          <div key={`RELATE_ARTICLE${id}`} className={LayoutRootStyles.topItem}>
            <Link to={`/news/${id}`} className={`uk-link-reset ${globalStyles.linkColor}`}>
              <li>{title}</li>
            </Link>
          </div>
        )
      })}
    </ul>
  )
  
  return (
    <>
      <div className={ArticleTempleteStyles.articleTitle}>相关文章</div>
      {relatedArticles.length > 0 ? relatedArticleList : '无'}
    </>
  )
}

export default RelatedArticles
